export const bidRules = {
  // bidName: [
  //   {
  //     required: true,
  //     message: '请输入标的名称',
  //     trigger: ['blur', 'change'],
  //   },
  // ],
  bidType: [
    {
      required: true,
      message: '请输入采购方式',
      trigger: ['blur', 'change'],
    },
  ],
  bidOwnerUnit: [
    {
      required: true,
      message: '请输入业主单位',
      trigger: ['blur', 'change'],
    },
  ],
  bidAgency: [
    {
      required: true,
      message: '请输入代理招标单位',
      trigger: ['blur', 'change'],
    },
  ],
  bidHostEscort: [
    {
      required: true,
      message: '请选择',
      trigger: ['blur', 'change'],
    },
  ],
  bidRegistrationDeadline: [
    {
      required: true,
      message: '请选择报名截止时间',
      trigger: ['blur', 'change'],
    },
  ],
  bidOpeningTime: [
    {
      required: true,
      message: '请选择开标时间',
      trigger: ['blur', 'change'],
    },
  ],
  bidOpeningLocation: [
    {
      required: true,
      message: '请输入开标地点',
      trigger: ['blur', 'change'],
    },
  ],
  bidOpeningMethod: [
    {
      required: true,
      message: '请输入开标方式',
      trigger: ['blur', 'change'],
    },
  ],
}
